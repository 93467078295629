import React, {useState, useEffect} from 'react';
import {Button, Dropdown} from "antd";
import {Handle} from 'react-flow-renderer';

import { NodeSelect } from './NodeSelect';
import {getFilterConditionTypes} from '../../services/ConditionService';
import {getProductFieldTextDisplayName} from '../../services/ProductHelperService';
import {ReactComponent as NodeIcon} from '../../../svg/node/filter-node-label.svg';
import {ReactComponent as DeleteIcon } from '../../../svg/sidebar/trash.svg';
import {ReactComponent as CreateNewIcon} from '../../../svg/node/create-new-node.svg';
import {ReactComponent as MoreIcon} from '../../../svg/node/more.svg';

import styles from './Nodes.module.css';
import NodeConditionSwitch from '../components/NodeConditionSwitch';

export default (props) => {
    const [filters, setFilters] = useState([]);
    const [condition, setCondition] = useState(props.data.condition ?? 'AND');
    const organization = props?.data?.project?.organization;
    const feed_fields = props.data.project?.feed.feed_fields;
    const fieldsData = feed_fields ? JSON.parse(feed_fields) : {};
    const fields = Object.keys(fieldsData).filter(f => f.indexOf('_changed') === -1 && f !== 'fresh').map(f => {
        return {name: getProductFieldTextDisplayName(f, fieldsData[f]), id: f, type: fieldsData[f]}
    })

    useEffect(() => {
        let propFilters = props.data.filters ?? [];
        propFilters = propFilters.filter(f => f !== null);
        setFilters(propFilters);
    }, [props.data.filters])

    if (!props.data.condition) props.data.condition = 'AND'

    const updateData = (filters) => {
        setFilters([...filters]);
        props.data.filters = [...filters];
    }

    const conditionChanged = (value) => {
        props.data.condition = value;
        setCondition(value);
    }

    const onAddFilterClick = () => {
        filters.push({
            field: Object.keys(fieldsData).filter(
                filter => filters.map(
                    ({field}) => field
                ).indexOf(filter) < 0
            )[0],
            op: '=',
            val: ''
        });
        updateData(filters);
    };

    return (
        <div className={styles.node}>
            <div className={`${styles.nodeHead} ${styles.filterNodeHead}`}>
                <NodeIcon />
                <span>Filter</span>
                <NodeConditionSwitch
                    condition={condition}
                    onConditionChanged={conditionChanged}
                    disabled={!organization.generation_allowed}
                />
            </div>
            <div className={`${styles.nodeBody} ${styles.filterNodeBody}`}>
                {filters.map((filter, index) => (
                    filter && (
                        <div className={`${styles.nodeBodyRow} ${styles.nodeBodyRowFilter}`} key={`filter_${index}`}>
                            <NodeSelect
                                className={`${styles.nodeData} ${styles.nodeDataFilter}`}
                                defaultValue={filter['field']}
                                onChange={(value) => {
                                    filters[index]['field'] = value;
                                    updateData(filters);
                                }}
                                disabled={!organization.generation_allowed}
                                options={fields.map((field) => ({ value: field.id, label: field.name }))}
                            />
                            <NodeSelect
                                className={`${styles.nodeData} ${styles.nodeDataFilter}`}
                                defaultValue={filter['op']}
                                onChange={(value) => {
                                    filters[index]['op'] = value
                                    if (value === 'EXISTS') {
                                        filters[index]['val'] = 0;
                                    }
                                    updateData(filters)
                                }}
                                disabled={!organization.generation_allowed}
                                options={getFilterConditionTypes(fields.find(fieldItem => fieldItem.id === filter.field)).map((field) => ({ value: field, label: field }))}
                            />
                            {filter['op'] === 'EXISTS' ? <NodeSelect
                              className={`${styles.nodeData} ${styles.nodeDataFilter}`}
                              value={filters[index]['val']}
                              onChange={(value) => {
                                  filters[index]['val'] = parseInt(value);
                                  updateData(filters);
                              }}
                              defaultValue={filters[index]['val'] || 0}
                              disabled={!organization.generation_allowed}
                              options={[
                                  { value: 1, label: 'true' },
                                  { value: 0, label: 'false' }
                              ]}
                            /> : <input
                              className={`${styles.nodeData} ${styles.nodeDataFilter}`}
                              type="text"
                              value={filter['val']}
                              onChange={(e) => {
                                  filters[index]['val'] = e.target.value;
                                  updateData(filters);
                              }}
                              disabled={!organization.generation_allowed}
                            />}
                            <Dropdown
                                className={styles.dropdown}
                                overlayClassName={styles.dropdownOverlay}
                                menu={
                                    {
                                        items: [
                                            {
                                                key: 'delete',
                                                label: (
                                                    <Button
                                                        onClick={() => {
                                                            filters.splice(index, 1);
                                                            updateData(filters);
                                                        }}
                                                        disabled={!organization.generation_allowed}
                                                        className={`${styles.dropdownButton} ${styles.dropdownButtonFilter}`}
                                                    >
                                                        <span>Delete</span> <DeleteIcon />
                                                    </Button>
                                                )
                                            }
                                        ]
                                    }
                                }
                            >
                                <Button>
                                    <MoreIcon />
                                </Button>
                            </Dropdown>
                        </div>
                    )
                ))}
                <Button
                    className={`${styles.nodeButton} ${styles.nodeButtonFilter}`}
                    onClick={onAddFilterClick}
                    disabled={!organization.generation_allowed}
                >
                    <span>Create new</span>
                    <CreateNewIcon />
                </Button>
            </div>

            <Handle
                type="target"
                position="left"
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={props.isConnectable}
                className={`${styles.nodeHandle} ${styles.nodeHandleLeft}`}
            />
            <Handle
                type="source"
                position="right"
                id="a"
                isConnectable={props.isConnectable}
                className={`${styles.nodeHandle} ${styles.nodeHandleRight}`}
            />
        </div>
    );
};
